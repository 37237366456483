import React from 'react';
import Lottie from 'react-lottie';

const LottieAnimation = props => {
  const { animationFile } = props;

  const options = {
    loop: true,
    autoplay: true,
    animationData: null,
    path: animationFile,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Lottie
      options={options}
      height={`100%`}
      width={`100%`}
      isClickToPauseDisabled={true}
      speed={1}
    />
  );
};

export default LottieAnimation;
