import styled from 'styled-components';

export const LoadingCover = styled.div`
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
`;

export const LottieAnimation = styled.div`
  width: 300px;
  display: flex;
  margin: 0 auto;
  height: 300px;
  margin-top: calc((100vh - 300px) / 2);
`;
