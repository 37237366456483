import styled from 'styled-components';

export const Loading = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  padding: 12px 7px 7px;
  background-color: #ebebeb;
  & img {
    height: 15px;
  }
`;

export const Editing = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  padding: 12px 7px 7px;
`;

export const Container = styled.div`
  & .subscriptions-select__single-value {
    color: #001c72 !important;
    background-color: #fff !important;
  }
  & .subscriptions-select__control {
    color: #001c72 !important;
    background-color: #fff !important;
  }
  & .subscriptions-select__menu {
    color: #001c72 !important;
    background-color: #fff !important;
  }
`;

export const OptionImage = styled.img`
  width: 25px;
  margin-right: 25px;
  position: absolute;
`;

export const OptionLabel = styled.span`
  margin-left: 35px;
  margin-top: 5px;
  display: block;
`;
