import axios from 'axios';
import utmTracking from './utmTracking';

export const fetchActiveSubscriptions = (apiUrl, token) => {
  return axios.get(apiUrl, {
    headers: { 'access-token': token }
  });
};

export const fetchUsersActiveSubscriptions = bearerToken => {
  const apiUrl = `${process.env.GATSBY_ENDPOINT_API_SITE}/api/subscription/list`;
  return axios.get(apiUrl, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  });
};

export const retryPaymentsSelectedSubscriptions = (
  ids = [],
  bearerToken,
  path
) => {
  const apiUrl = `${process.env.GATSBY_ENDPOINT_API_SITE}/api/payments/retry`;
  return axios.post(
    apiUrl,
    {
      selectedSubscriptions: ids,
      utmTracking: utmTracking(path)
    },
    {
      headers: { Authorization: `Bearer ${bearerToken}` }
    }
  );
};

export const addToSubscription = props => {
  const { apiUrl, extra, checkoutData } = props;
  const {
    active_subscription_id,
    active_subscription_token,
    active_subscription_special_offer,
    active_subscription_discount_type,
    active_subscription_discount_code,
    active_subscription_send_discount_code
  } = extra;

  const discountType = () => {
    switch (active_subscription_discount_type) {
      case 'DISCOUNT_55':
        return 6;
      case 'DISCOUNT_40':
        return 5;
      case 'DISCOUNT_35':
        return 4;
      case 'DISCOUNT_FREE_UNIT':
        return 3;
      case 'DISCOUNT_50':
        return 2;
      default:
      case 'DISCOUNT_25':
        return 1;
    }
  };

  let payload = {
    variantId: checkoutData.variant_id,
    quantity: checkoutData.quantity,
    specialOffer: active_subscription_special_offer,
    subscriptionId: active_subscription_id
  };

  if (active_subscription_send_discount_code) {
    Object.assign(payload, {
      discountCode: active_subscription_discount_code
    });
  } else {
    Object.assign(payload, {
      applyDiscountType: discountType()
    });
  }

  return axios.post(apiUrl, payload, {
    headers: { 'access-token': active_subscription_token }
  });
};

export const addNextMonthRebillDiscountTag = (
  bearerToken,
  subscriptionId,
  tag
) => {
  const apiUrl = `${process.env.GATSBY_ENDPOINT_API_SITE}/api/subscription/${subscriptionId}/next-month-gift`;
  axios.post(
    apiUrl,
    {
      next_month_rebill_discount_tag: tag
    },
    {
      headers: { Authorization: `Bearer ${bearerToken}` }
    }
  );
};
