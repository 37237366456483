import React from 'react';
import * as S from '../LoadingCover/LoadingCover.styles';
import LottieAnimation from '../LottieAnimation/LottieAnimation';

const LoadingCover = props => {
  return (
    <S.LoadingCover>
      <S.LottieAnimation>
        <LottieAnimation animationFile="https://cdn.builder.io/o/assets%2Fe9ed5fb489ab4b769611bc610a472a56%2F302066eb88bc4ee5a0f6bd4d894e32aa?alt=media&token=9068a8aa-9cea-4a77-b69c-f623c17720c0" />
      </S.LottieAnimation>
    </S.LoadingCover>
  );
};

export default LoadingCover;
