import React, { useState, useEffect, useContext, useCallback } from 'react';
import * as S from './ActiveSubscriptionSelectorV1.styles';
import FunnelContext from '../../context/FunnelContext';
import Select, { components } from 'react-select';
import { Builder } from '@builder.io/react';
import { fetchActiveSubscriptions } from '../../utils/activeSubscriptions';
import { getParamFromQuery } from '../../utils/getParamFromQuery';
import useLocalStorage from '../../hooks/useLocalStorage';

const ActiveSubscriptionSelector = props => {
  const {
    expiredTokenRedirect,
    endpointApiUrl,
    specialOffer,
    discountType,
    sendDiscountCode,
    discountCode
  } = props;

  const [options, setOptions] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [, setCrossSellSettings] = useLocalStorage('cross-sell-settings');

  const { currency, extraObjects, setExtraObjects } = useContext(FunnelContext);

  const currencySymbol = currency?.symbol || '$';

  const initConfig = useCallback(
    token => {
      setExtraObjects(
        Object.assign(extraObjects, {
          active_subscription_token: token,
          active_subscription_special_offer: specialOffer || 0,
          active_subscription_discount_type: discountType || 'DISCOUNT_25',
          active_subscription_send_discount_code: sendDiscountCode || false,
          active_subscription_discount_code: discountCode || ''
        })
      );
    },
    [
      extraObjects,
      setExtraObjects,
      specialOffer,
      discountType,
      discountCode,
      sendDiscountCode
    ]
  );

  const initCurrentSub = useCallback(
    result => {
      setExtraObjects(
        Object.assign(extraObjects, {
          active_subscription: result,
          active_subscription_id: result?.subscriptionId
        })
      );
    },
    [extraObjects, setExtraObjects]
  );

  useEffect(() => {
    if (!fetched) {
      const token = getParamFromQuery('token');

      if (!token || token === ``) {
        if (!Builder.isEditing && !(process.env.NODE_ENV === 'development')) {
          window.location.href = expiredTokenRedirect;
        }
      }

      initConfig(token);

      if (!loaded) {
        setLoaded(true);
        fetchActiveSubscriptions(endpointApiUrl, token)
          .then(function(response) {
            const results = response.data;
            setOptions(results);
            initCurrentSub(results[0] || {});
            setFetched(true);

            setCrossSellSettings({
              specialOffer: specialOffer || 0,
              discountType: discountType || 'DISCOUNT_25',
              sendDiscountCode: sendDiscountCode || false,
              discountCode: discountCode || ''
            });
          })
          .catch(function(error) {
            console.log(error);
            if (
              !Builder.isEditing &&
              !(process.env.NODE_ENV === 'development')
            ) {
              window.location.href = expiredTokenRedirect;
            }
          });
      }
    }
  }, [
    loaded,
    setLoaded,
    fetched,
    setFetched,
    expiredTokenRedirect,
    setOptions,
    initCurrentSub,
    endpointApiUrl,
    initConfig,
    specialOffer,
    discountType,
    setCrossSellSettings,
    discountCode,
    sendDiscountCode
  ]);

  const optionsData = options.map(item => {
    const product_name = item.mainProductName;
    const total_price = item.totalAmount.toFixed(2);
    const label = `${product_name} (${currencySymbol}${total_price})`;
    return {
      value: item.subscriptionId,
      label: label,
      result: item,
      icon: item.image
    };
  });

  const handleSelectChange = ({ result }) => {
    initCurrentSub(result);
  };

  const SelectContainer = () => {
    const { Option, SingleValue } = components;
    const IconOption = props => (
      <Option {...props}>
        <S.OptionImage src={props.data.icon} alt={props.data.label} />
        <S.OptionLabel>{props.data.label}</S.OptionLabel>
      </Option>
    );

    const SingleValue2 = props => (
      <SingleValue {...props}>
        <S.OptionImage src={props.data.icon} alt={props.data.label} />
        <S.OptionLabel>{props.data.label}</S.OptionLabel>
      </SingleValue>
    );

    return (
      <Select
        classNamePrefix="subscriptions-select"
        options={optionsData}
        onChange={handleSelectChange}
        inputProps={{ readOnly: true }}
        isSearchable={false}
        defaultValue={optionsData.filter(
          option => option.value === extraObjects?.active_subscription_id
        )}
        components={{ Option: IconOption, SingleValue: SingleValue2 }}
      />
    );
  };

  return (
    options !== null && (
      <S.Container>
        {fetched && <SelectContainer />}
        {!fetched && !Builder.isEditing && (
          <S.Loading>
            <img src="/images/three-dots.svg" alt="loading" />
          </S.Loading>
        )}
        {!fetched && Builder.isEditing && (
          <S.Editing>- - - Active Subscriptions Here - - -</S.Editing>
        )}
      </S.Container>
    )
  );
};

export default ActiveSubscriptionSelector;
