import React from 'react';
import ActiveSubscriptionSelectorV1 from './ActiveSubscriptionSelectorV1';
import ActiveSubscriptionSelectorV2 from './ActiveSubscriptionSelectorV2';
import ActiveSubscriptionSelectorV3 from './ActiveSubscriptionSelectorV3';

const ActiveSubscriptionSelector = props => {
  const { designVersion } = props;

  switch (designVersion) {
    case 'version3':
      return <ActiveSubscriptionSelectorV3 {...props} />;
    case 'version2':
      return <ActiveSubscriptionSelectorV2 {...props} />;
    default:
    case 'version1':
      return <ActiveSubscriptionSelectorV1 {...props} />;
  }
};

export default ActiveSubscriptionSelector;
