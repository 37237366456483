import styled from 'styled-components';

export const Container = styled.div``;

export const Loading = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  padding: 12px 7px 7px;
  background-color: #ebebeb;
  & img {
    height: 15px;
  }
`;

export const Button = styled.div`
  background: #bc2e3e;
  border-radius: 4px;
  text-align: center;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  font-family: 'Neuzeit Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  padding: 18px;
  color: #ffffff;
  margin-top: 15px;
  cursor: pointer;
  &:hover {
    background: #a42836;
  }
  &.disabled {
    opacity: 0.5;
    &:hover {
      background: #bc2e3e;
    }
  }
`;

export const ExistsMessage = styled.p`
  ${({ isCentered }) => (isCentered ? `` : `text-align: center;`)}
`;

export const LoadingCover = styled.div`
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
`;

export const LottieAnimation = styled.div`
  width: 300px;
  display: flex;
  margin: 0 auto;
  height: 300px;
  margin-top: calc((100vh - 300px) / 2);
`;

export const TopText = styled.div`
  font-size: 16px;
`;

export const OptionImage = styled.img`
  width: 25px;
  margin-right: 25px;
  position: absolute;
`;

export const OptionLabel = styled.span`
  margin-left: 35px;
  margin-top: 5px;
  display: block;
`;
