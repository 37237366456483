import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  z-index: 1000;
  background: rgb(204 204 204 / 50%);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: none;
  &.active {
    display: block;
  }
`;

export const Container = styled.div`
  position: fixed;
  max-width: 475px;
  width: 100%;
  left: calc((100% - 475px) / 2);
  top: 120px;
  z-index: 1001;
  @media only screen and (max-width: 640px) {
    padding: 0 10px;
    left: 0;
    max-width: 100%;
    width: unset;
  }
`;

export const Modal = styled.div`
  border-radius: 4px;
  border: 1px solid #ccd2e3;
  max-height: 865px;
  background: linear-gradient(180deg, #ffffff 0%, #fafbfc 49.14%, #f5f7f9 100%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
`;
export const Header = styled.div`
  padding: 20px;
  border-radius: 4px 4px 0 0;
  background: #001c72;
  font-family: 'Utopia Std';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
`;
export const Body = styled.div`
  padding: 32px;
  background: #fff;
  border-radius: 0 0 4px 4px;
  &.no-header {
    border-radius: 4px;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
`;
export const ProductImage = styled.img`
  width: 100px;
  height: 100px;
  margin-right: 20px;
`;

export const ProductDetails = styled.div`
  margin: auto 0;
  &.success {
    font-family: 'Utopia Std';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    margin-top: auto;
    margin-bottom: auto;
    & > span {
      color: #bc2e3e;
    }
  }
`;
export const ProductTitle = styled.div`
  font-family: 'Utopia Std';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #001c72;
  margin-bottom: 5px;
`;
export const ProductQuantity = styled.div`
  font-family: 'Utopia Std';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #001c72;
  margin-bottom: 5px;
`;
export const ProductAmount = styled.div`
  font-family: 'Neuzeit Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #bc2e3e;
`;

export const Summary = styled.div`
  border-top: 1px solid rgba(0, 28, 114, 0.2);
  padding-top: 20px;
  &.success {
    border: none;
  }
`;

export const SummaryItem = styled.div`
  font-family: 'Utopia Std';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  gap: 4px;
  background: #f5f7f9;
  margin-bottom: 10px;
  &.success {
    padding: 4px;
  }
`;
export const SummaryItemTitle = styled.div`
  &.total {
    font-size: 20px;
    line-height: 24px;
  }
`;
export const SummaryItemAmount = styled.div`
  font-family: 'Neuzeit Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #888888;
  &.total {
    color: #bc2e3e;
  }
  &.success {
    color: #001c72;
  }
`;

export const Message = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
`;

export const Button = styled.div`
  background: #bc2e3e;
  border-radius: 4px;
  text-align: center;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  font-family: 'Neuzeit Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  padding: 18px;
  color: #ffffff;
  margin-top: 15px;
  cursor: pointer;
  &:hover {
    background: #a42836;
  }
`;

export const SuccessTitle = styled.div`
  font-family: 'Utopia Std';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  color: #001c72;
  text-align: center;
  border-bottom: 1px solid rgba(0, 28, 114, 0.2);
  margin-bottom: 20px;
  padding-bottom: 20px;
`;

export const Animation = styled.div`
  width: 200px;
  height: 200px;
  margin: auto;
  display: block;
  & > div > div {
    position: relative;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 32px;
  top: 20px;
  cursor: pointer;
  &.success {
    color: #001c72;
    font-size: 32px;
  }
`;
